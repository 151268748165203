/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import CookieConsent from "react-cookie-consent"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faLinkedin } from "@fortawesome/free-brands-svg-icons"
import { useIntl, changeLocale, Link } from "gatsby-plugin-intl"

import "./layout.css"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  const intl = useIntl()

  const [scrollTop, setScrollTop] = useState(0)
  const [open, setOpen] = useState(false)

  useEffect(() => {
    const onScroll = e => {
      setScrollTop(e.target.documentElement.scrollTop)
    }
    window.addEventListener("scroll", onScroll)

    return () => window.removeEventListener("scroll", onScroll)
  }, [scrollTop])

  const isBrowser = typeof window !== `undefined`

  return (
    <div className="h-100 bg-sec-color flex flex-column">
      <CookieConsent
        location="bottom"
        buttonText="Accetta cookies"
        cookieName="Time2ClientCookie"
        style={{ background: "#090606" }}
        buttonStyle={{
          background: "transparent",
          color: "#ffffff",
          border: "#ab987a",
          fontSize: "13px",
        }}
        expires={150}
      >
        Questo sito utilizza cookies per assicurare una migliore esperienza sul
        nostro sito.
      </CookieConsent>
      <nav
        className={`fixed w-100 z-999 flex justify-between ph3 bg-black-20 nav-default ${
          scrollTop > 50 ? "nav-shrink" : ""
        }`}
      >
        <div className="flex items-center">
          <Link
            to={isBrowser && window.location.pathname === "/" ? "#top" : "/"}
            className="flex items-center tc ph2 white hover-white-70 dim"
          >
            <img
              src="/images/logo_white_transparent.png"
              className="nav-logo-default"
              alt=""
            />
            <span className="db ml1 white f3 f2-l cinzel">Time2Client</span>
          </Link>
        </div>
        <div id="menu" className="flex items-center pointer">
          <span className="ibm f3 ph4 ttu tracked mega white">Menu</span>
          <div
            id="menu-icon"
            className={`relative mv0 center ${open ? "open" : ""}`}
            style={{ width: "40px" }}
            onClick={() => {
              setOpen(!open)
            }}
          >
            <span
              className="db absolute bg-white w-100"
              style={{ height: "2px" }}
            ></span>
            <span
              className="db absolute bg-white w-100"
              style={{ height: "2px" }}
            ></span>
            <span
              className="db absolute bg-white w-100"
              style={{ height: "2px" }}
            ></span>
            <span
              className="db absolute bg-white w-100"
              style={{ height: "2px" }}
            ></span>
          </div>
        </div>
      </nav>

      <div className={`bg-dark-color  ${open ? "open" : ""}`} id="full-nav">
        <div className="relative top--25 tc mw7 center">
          <Link className="db mv4 hover-sec-color f2 f1-ns white ibm" to="/">
            Inizio
          </Link>

          <Link
            className="db mv4 hover-sec-color f2 f1-ns white ibm"
            to="/experience"
          >
            Comunicazione ed esperienza del cliente
          </Link>

          <Link
            className="db mv4 hover-sec-color f2 f1-ns white ibm"
            to="/custom"
          >
            Personalizzazione
          </Link>

          <Link
            className="db mv4 hover-sec-color f2 f1-ns white ibm"
            to="/technology"
          >
            Tecnologia
          </Link>

          <a
            href="https://blog.time2client.com"
            className="db mv4 hover-sec-color f2 f1-ns white ibm"
          >
            Magazine
          </a>
        </div>

        <div className="dn db-ns absolute bottom-0 pa4">
          <a
            className="no-underline near-white hover-white bg-animate bg-near-black hover-bg-sec-color inline-flex items-center ma2 tc br2 pa2"
            href="https://www.linkedin.com/company/time2client/"
            title="Linkedin"
          >
            <FontAwesomeIcon icon={faLinkedin} size="2x" />
            <span className="f6 ml3 pr2">Linkedin</span>
          </a>
        </div>

        <div className="flex w-100 w-auto-ns absolute right-0-ns top-0 justify-between pa4 mt5">
          <a
            className="db ma0 ma3-ns pa3 bb ba-ns br3-ns b--white hover-b--sec-color hover-sec-color f4 f3-ns white ibm"
            href="https://app.time2client.com/it/member/session/new"
            rel="nofollow"
          >
            Entra come avvocato
          </a>
          <Link
            to="/search"
            className="db ma0 ma3-ns pa3 bb ba-ns br3-ns b--white hover-b--sec-color hover-sec-color f4 f3-ns white ibm"
            rel="nofollow"
          >
            Entra come cliente
          </Link>
        </div>
        <div className="absolute right-0 bottom-0 pa4">
          <span
            className={`ibm f3 f2-ns ph3 ${
              intl.locale === "it"
                ? "sec-color"
                : "white hover-sec-color pointer link"
            }`}
            onClick={() => {
              changeLocale("it")
            }}
          >
            IT
          </span>
          <span
            className={`ibm f3 f2-ns ph3 ${
              intl.locale === "en"
                ? "sec-color"
                : "white hover-sec-color pointer link"
            }`}
            onClick={() => {
              changeLocale("en")
            }}
          >
            EN
          </span>
        </div>
      </div>
      <main className="bg-white">{children}</main>

      <footer className="mt-0 flex-shrink bg-dark-color white-80 pv5 pv6-l ph4">
        <div className="flex justify-between items-center">
          <p className="db f6">
            <span className="dib mr4 mr5-ns">
              © {new Date().getFullYear()} Time2Client
            </span>
            <a
              href="https://www.iubenda.com/termini-e-condizioni/64039599"
              className="link white-80 hover-light-purple"
            >
              Termini e Condizioni
            </a>{" "}
            /
            <a
              href="https://www.iubenda.com/privacy-policy/64039599"
              className="link white-80 hover-gold"
            >
              Privacy Policy
            </a>{" "}
            /
            <a
              href="https://www.iubenda.com/privacy-policy/64039599/cookie-policy"
              className="link white-80 hover-gold"
            >
              Cookie Policy
            </a>{" "}
            /
            <Link className="link white-80 hover-light-green" to="/contacts">
              Contatti
            </Link>
          </p>
          <p className="db mv0">
            <a
              className="no-underline near-white hover-white bg-animate bg-near-black hover-bg-sec-color inline-flex items-center ma2 tc br2 pa2"
              href="https://www.linkedin.com/company/time2client/"
              title="Linkedin"
            >
              <FontAwesomeIcon icon={faLinkedin} size="2x" />
              <span className="f6 ml3 pr2">Linkedin</span>
            </a>
          </p>
        </div>
      </footer>
    </div>
  )
}

export default Layout
